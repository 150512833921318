import { Amplify } from 'aws-amplify';
import { COGNITO_APP_ID, COGNITO_REDIRECT_URL } from 'shared/utils/appConfig';

export const configureAmplify = () => {
    Amplify.configure({
        Auth: {
            mandatorySignIn: false,
            region: 'eu-west-1',
            userPoolId: 'eu-west-1_MEIoAybm0',
            userPoolWebClientId: COGNITO_APP_ID,

            oauth: {
                domain: 'eiap.auth.eu-west-1.amazoncognito.com',
                scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
                redirectSignIn: `${COGNITO_REDIRECT_URL}`,
                redirectSignOut: `${COGNITO_REDIRECT_URL}`,
                responseType: 'code' // or 'token', note that REFRESH token wi be generated when the responseType is code
            }
        }
    });
};
