import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import 'customers/components/loading-errors-handler/errorHandler.scss';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import {
    Card,
    CardContent,
    CardHeader,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import { Link as RouterLink } from 'react-router-dom';
import { PortableWifiOff } from '@mui/icons-material';
import classNames from 'classnames';
import emptyPictoIcon from 'assets/empty.png';

interface Props {
    error?: boolean;
    errorMessage?: string | null;
    loading?: boolean;
    loadingGrid?: boolean;
    loadingUserOverview?: boolean;
    link?: string;
    catalog?: boolean;
    list?: boolean;
    hasNetworkPicto?: boolean;
    hasEmptyPicto?: boolean;
    primary?: boolean;
}

export const LoadingErrorsHandler: React.FunctionComponent<Props> = ({
    error,
    errorMessage,
    loading,
    loadingGrid,
    loadingUserOverview,
    catalog,
    list,
    link,
    hasEmptyPicto,
    hasNetworkPicto,
    primary
}: Props): JSX.Element => {
    return (
        <>
            <main className={['main', 'error-handler'].join(' ')}>
                {error && (
                    <div className={'message'}>
                        {link && (
                            <>
                                <div className={'message-small'}>{errorMessage}</div>
                                <RouterLink to={link} className={'no-decoration'}>
                                    <Button color="secondary" className={'btn-primary'}>
                                        Access the catalog
                                        <ArrowForwardIcon />
                                    </Button>
                                </RouterLink>
                            </>
                        )}
                        {!link && (
                            <div
                                className={classNames('message-small', {
                                    primary: primary
                                })}>
                                {hasEmptyPicto && (
                                    <img className={'msg-icon-empty'} src={emptyPictoIcon} />
                                )}
                                {hasNetworkPicto && <PortableWifiOff />}
                                {errorMessage}
                            </div>
                        )}
                    </div>
                )}
                {loading && !catalog && !list && (
                    <div className={'loading'} data-testid={'loading'}>
                        <CircularProgress thickness={2} disableShrink color="secondary" />
                    </div>
                )}

                {list && !error && (
                    <List>
                        {Array(...Array(12)).map((el: any, index: number) => {
                            return (
                                <ListItem
                                    key={'list_skeleton_' + index}
                                    dense
                                    disablePadding
                                    style={{ background: '#fff' }}>
                                    <ListItemIcon>
                                        <Skeleton
                                            animation="wave"
                                            width={40}
                                            height={70}
                                            style={{ margin: 'auto', marginLeft: 16 }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Skeleton
                                                animation="wave"
                                                height={20}
                                                width="35%"
                                                style={{
                                                    marginBottom: 4,
                                                    marginLeft: 14
                                                }}
                                            />
                                        }
                                        secondary={
                                            <Skeleton
                                                animation="wave"
                                                height={14}
                                                width="95%"
                                                style={{
                                                    marginBottom: 2,
                                                    marginLeft: 14
                                                }}
                                            />
                                        }
                                    />
                                </ListItem>
                            );
                        })}
                    </List>
                )}

                {catalog && !error && (
                    <Grid container spacing={2}>
                        {Array(...Array(12)).map((index: number) => {
                            return (
                                <Grid
                                    key={'catalog_skeleton_' + index}
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={3}
                                    xl={2}>
                                    <Card style={{ height: '230px' }} className={'card'}>
                                        <CardHeader
                                            avatar={
                                                <Skeleton
                                                    animation="wave"
                                                    variant="circular"
                                                    width={40}
                                                    height={40}
                                                    style={{ margin: 'auto' }}
                                                />
                                            }
                                            title={
                                                <Skeleton
                                                    animation="wave"
                                                    height={10}
                                                    width="80%"
                                                    style={{
                                                        marginBottom: 6,
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto'
                                                    }}
                                                />
                                            }
                                            subheader={
                                                <Skeleton
                                                    animation="wave"
                                                    height={10}
                                                    width="40%"
                                                    style={{
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto'
                                                    }}
                                                />
                                            }
                                        />

                                        <CardContent>
                                            <Skeleton
                                                animation="wave"
                                                height={10}
                                                style={{ marginBottom: 6 }}
                                            />
                                            <Skeleton
                                                animation="wave"
                                                height={10}
                                                style={{ marginBottom: 6 }}
                                            />
                                            <Skeleton
                                                animation="wave"
                                                height={10}
                                                style={{ marginBottom: 6 }}
                                            />
                                            <Skeleton
                                                animation="wave"
                                                height={10}
                                                style={{ marginBottom: 6 }}
                                            />
                                            <Skeleton
                                                animation="wave"
                                                height={10}
                                                style={{ marginBottom: 6 }}
                                            />
                                            <Skeleton
                                                animation="wave"
                                                height={10}
                                                style={{ marginBottom: 6 }}
                                            />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            );
                        })}
                    </Grid>
                )}
                {loadingUserOverview && (
                    <Grid container spacing={3} justifyContent="center">
                        {Array(...Array(4)).map((index: number) => {
                            return (
                                <Grid key={index} item xs={12} sm={3}>
                                    <div className={'userOverview'}>
                                        <Skeleton
                                            animation="wave"
                                            className={'avatar'}
                                            variant="circular"
                                            width={60}
                                            height={60}
                                        />

                                        <div className={'content'}>
                                            <Skeleton
                                                animation="wave"
                                                height={20}
                                                width="60%"
                                                className={'title'}
                                            />

                                            <Skeleton
                                                animation="wave"
                                                height={10}
                                                style={{ marginBottom: 6 }}
                                            />
                                            <Skeleton
                                                animation="wave"
                                                height={10}
                                                style={{ marginBottom: 6 }}
                                            />
                                            <Skeleton
                                                animation="wave"
                                                height={10}
                                                style={{ marginBottom: 6 }}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            );
                        })}
                    </Grid>
                )}
            </main>
        </>
    );
};
