import { createTheme } from '@mui/material';

export default createTheme({
    palette: {
        primary: { main: '#124191' },
        secondary: { main: '#00c9ff' }
    },
    breakpoints: {
        keys: ['xs', 'sm', 'md', 'lg', 'xl'],
        values: { xs: 568, sm: 760, md: 1200, lg: 1600, xl: 1800 }
    }
});
