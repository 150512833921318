import { LinearProgress } from '@mui/material';
import React from 'react';

const LoadingBackdrop = () => {
    return (
        <LinearProgress
            style={{ position: 'absolute', width: '100%', top: '0', left: '0', right: '0' }}
        />
    );
};
export default LoadingBackdrop;
