import { LOCALSTORAGE_TOUR_GUIDE } from 'customers/utils/constants/general.constants';
import { TourStep } from 'shared/utils/interfaces/interfaces';

export const loadFromLocalStorage = (item: string): any => {
    try {
        const serializedState = localStorage.getItem(item);
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

export const saveToLocalStorage = (item: string, content: any) => {
    try {
        const serializedState = JSON.stringify(content);
        localStorage.setItem(item, serializedState);
    } catch {
        // ignore write errors
    }
};
export const getNewStepsOfTourSinceLastVisit = (
    tourName: string,
    steps: TourStep[]
): TourStep[] => {
    //todo: handle backward compatibility, to be removed
    const oldVersionStorage = [
        'landing-welcome',
        'landing-workspace',
        'landing-documentation',
        'landing-help-menu',
        'development-your-workspace',
        'development-workspaces',
        'development-software',
        'development-devices',
        'development-documentation',
        'development-help',
        'development-studio'
    ];

    const localStorageTourGuide = loadFromLocalStorage(LOCALSTORAGE_TOUR_GUIDE) || {};

    //todo: handle backward compatibility, to be removed
    if (localStorageTourGuide[tourName] && localStorageTourGuide[tourName] === '1') {
        return steps.filter((step: TourStep) => !oldVersionStorage.includes(step.id));
    } else if (localStorageTourGuide[tourName] && Array.isArray(localStorageTourGuide[tourName])) {
        return steps.filter((step: TourStep) => !localStorageTourGuide[tourName].includes(step.id));
    } else {
        return steps;
    }
};

export const saveTourToLocalStorage = (tourName: string, steps: TourStep[]) => {
    const ls = loadFromLocalStorage(LOCALSTORAGE_TOUR_GUIDE) || {};
    ls[tourName] = steps.map((step: TourStep) => step.id);
    saveToLocalStorage(LOCALSTORAGE_TOUR_GUIDE, ls);
};
