import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_BASE_URL } from 'shared/utils/appConfig';
import { DocumentationItem } from 'shared/utils/interfaces/interfaces';
import { getCurrentUserJwtToken } from 'shared/utils/helpers/currentUser.helpers';

export const documentationApi = createApi({
    reducerPath: 'documentationApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${API_BASE_URL}/items`,
        prepareHeaders: async (headers) => {
            const accessToken = await getCurrentUserJwtToken();
            headers.set('Content-Type', 'application/json');
            headers.set('Authorization', `Bearer ${accessToken}`);
            return headers;
        }
    }),
    tagTypes: ['documentation', 'documentations'],
    endpoints: (builder) => ({
        getDocumentation: builder.query<DocumentationItem, string>({
            query: (id) => `items/${id}?docs=true`,
            transformResponse: (response: any) => {
                return response.item;
            },
            providesTags: ['documentation']
        }),
        getDocumentations: builder.query<DocumentationItem[], void>({
            query: (id) => `items?docs=true`,
            transformResponse: (response: any) => {
                return response.items;
            },
            providesTags: ['documentations']
        })
    })
});

export const { useGetDocumentationQuery, useGetDocumentationsQuery } = documentationApi;
