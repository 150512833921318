import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { cloudsApi } from 'store/clouds/cloudsApi';
import { createLogger } from 'redux-logger';
import { workspacesApi } from 'store/workspaces/workspacesApi';
import { documentationApi } from 'store/documentation/documentationApi';
import { devicesApi } from 'store/devices/devicesApi';
import { instancesApi } from 'store/instances/InstancesApi';
import { itemsApi } from 'store/items/itemsApi';
import { instanceLogsApi } from 'store/instances/InstanceLogsApi';
import { instancesStatusApi } from 'store/instances/InstancesStatusApi';
import { currentWorkspaceSlice } from 'store/workspaces/currentWorkspaceSlice';
import { groupsApi } from 'store/groups/groupsApi';

const logger = createLogger({
    collapsed: true
});

export const setupStore = configureStore({
    reducer: {
        [cloudsApi.reducerPath]: cloudsApi.reducer,
        [workspacesApi.reducerPath]: workspacesApi.reducer,
        [groupsApi.reducerPath]: groupsApi.reducer,
        [devicesApi.reducerPath]: devicesApi.reducer,
        [instancesApi.reducerPath]: instancesApi.reducer,
        [itemsApi.reducerPath]: itemsApi.reducer,
        [documentationApi.reducerPath]: documentationApi.reducer,
        [instanceLogsApi.reducerPath]: instanceLogsApi.reducer,
        [instancesStatusApi.reducerPath]: instancesStatusApi.reducer,
        currentWorkspaceSlice: currentWorkspaceSlice.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: { warnAfter: 128 },
            serializableCheck: false
        }).concat(
            cloudsApi.middleware,
            workspacesApi.middleware,
            groupsApi.middleware,
            devicesApi.middleware,
            instancesApi.middleware,
            itemsApi.middleware,
            documentationApi.middleware,
            instancesStatusApi.middleware,
            instanceLogsApi.middleware
        )
});

export type AppDispatch = typeof setupStore.dispatch;
export type RootState = ReturnType<typeof setupStore.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
