import React, { lazy, Suspense, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ROUTE } from 'customers/utils/constants/general.constants';
import { LoadingErrorsHandler } from 'customers/components/loading-errors-handler/LoadingErrorsHandler';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { WORKSPACE_CODE } from 'customers/utils/enums/enums';
import { getCurrentWorkspace, setCurrentWorkspace } from 'store/workspaces/currentWorkspaceSlice';
import { useGetWorkspacesQuery } from 'store/workspaces/workspacesApi';
import { Workspace } from 'shared/utils/interfaces/interfaces';
import 'customers/pages/Customers.scss';
import LoadingBackdrop from 'shared/components/loading/LoadingBackdrop';

const MyStudio = lazy(() => import('customers/pages/my-studio/MyStudio'));
const WorkspaceError = lazy(() => import('customers/pages/workspace-error/WorkspaceError'));
const Documentation = lazy(() => import('customers/pages/documentation/Documentation'));
const Landing = lazy(() => import('customers/pages/landing/Landing'));
const Catalog = lazy(() => import('customers/pages/catalog/Catalog'));
const AccessApplication = lazy(
    () => import('customers/pages/access-application/AccessApplication')
);
const Instantiate = lazy(() => import('customers/pages/instantiate/Instantiate'));
const Cloud = lazy(() => import('customers/pages/clouds/Clouds'));
const ItemPage = lazy(() => import('customers/pages/item-page/ItemPage'));
const Devices = lazy(() => import('customers/pages/devices/Devices'));
const Software = lazy(() => import('customers/pages/software/Software'));
const Users = lazy(() => import('customers/pages/users/Users'));

const CustomersRouter = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const [previousLocation, setPreviousLocation] = useState<string>('');
    const currentWorkspace = useAppSelector((state) => state.currentWorkspaceSlice.workspace);
    const currentWorkspaceLoading = useAppSelector((state) => state.currentWorkspaceSlice.loading);
    const currentWorkspaceError = useAppSelector((state) => state.currentWorkspaceSlice.error);
    const currentWorkspaceCode = useAppSelector((state) => state.currentWorkspaceSlice.code);
    const { data: workspaces } = useGetWorkspacesQuery();

    React.useEffect(() => {
        dispatch(getCurrentWorkspace());
    }, []);

    React.useEffect(() => {
        if (location.pathname.split('/')[1] !== previousLocation && workspaces) {
            if (
                workspaces.find(
                    (w: Workspace) => w.workspace_id === location.pathname.split('/')[1]
                )
            ) {
                setPreviousLocation(location.pathname.split('/')[1]);
                dispatch(setCurrentWorkspace({ workspace_id: location.pathname.split('/')[1] }));
            }
        }
    }, [location]);

    return (
        <Suspense fallback={<LoadingBackdrop />}>
            <Routes>
                <Route key={'/'} path={`/`} element={<Landing />} />
                <Route
                    path={`/registration/:workspaceId/:registrationToken`}
                    key={`/registration/:workspace/:registrationToken`}
                    element={<Landing />}
                />
                ,
                <Route
                    path={`/${ROUTE.DOCUMENTATION}/`}
                    key={`/${ROUTE.DOCUMENTATION}/`}
                    element={<Documentation />}
                />
                <Route path={`/${ROUTE.STUDIO}`} key={`/${ROUTE.STUDIO}`} element={<MyStudio />} />,
                {!currentWorkspaceLoading &&
                    currentWorkspaceCode === WORKSPACE_CODE.OK &&
                    currentWorkspace && [
                        <Route
                            path={`/:workspace`}
                            key={`/:workspace`}
                            element={
                                <Navigate
                                    to={`/${window.location.pathname.split('/')[1]}/software`}
                                />
                            }
                        />,
                        <Route
                            path={`/:workspace/software`}
                            key={`/:workspace/software`}
                            element={<Software />}
                        />,
                        <Route
                            path={`/:workspace/devices`}
                            key={`/:workspace/devices`}
                            element={<Devices />}
                        />,

                        <Route
                            path={`/:workspace/users`}
                            key={`/:workspace/users`}
                            element={<Users />}
                        />,
                        <Route
                            path={`/:workspace/software/view/:id`}
                            key={`/:workspace/software/view/:id`}
                            element={<AccessApplication />}
                        />,
                        <Route
                            path={`/:workspace/software/catalog`}
                            key={`/:workspace/software/catalog`}
                            element={<Catalog />}
                        />,
                        <Route
                            path={`/:workspace/software/catalog/install/:id`}
                            key={`/:workspace/software/catalog/install/:id`}
                            element={<Instantiate />}
                        />,
                        <Route
                            path={`/:workspace/software/edit/:id`}
                            key={`/:workspace/software/edit/:id`}
                            element={<Instantiate edit />}
                        />,
                        <Route
                            path={`/:workspace/clouds`}
                            key={`/:workspace/clouds`}
                            element={<Cloud />}
                        />,

                        <Route
                            path={`/:workspace/software/catalog/item/:id`}
                            key={`/:workspace/software/catalog/item/:id`}
                            element={<ItemPage />}
                        />
                    ]}
                {!currentWorkspaceLoading &&
                    currentWorkspaceCode === WORKSPACE_CODE.NOT_ALLOWED && (
                        <Route path="*" element={<WorkspaceError />} />
                    )}
                {currentWorkspaceError && (
                    <Route
                        path="*"
                        element={
                            <LoadingErrorsHandler
                                hasNetworkPicto
                                error={true}
                                errorMessage={'Unable to access the server.'}
                            />
                        }
                    />
                )}
                {currentWorkspaceLoading && <Route path="*" element={<LoadingBackdrop />} />}
            </Routes>
        </Suspense>
    );
};

export default CustomersRouter;
