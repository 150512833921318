export enum ITEM_TYPE {
    SERVICE = 'service',
    APPLICATION = 'application'
}
export enum CLOUDS_SSE_EVENT {
    UPDATE = 'cloudUpdate',
    CREATE = 'cloudCreation',
    DELETE = 'cloudDeletion'
}

export enum UPDATE_STATUS {
    FAILED = 'failed',
    UPDATING = 'updating',
    RUNNING = 'running',
    CREATING = 'creating',
    DELETING = 'deleting'
}

export enum CLOUD_STATUS {
    OFFLINE = 'offline',
    ONLINE = 'online'
}

export enum ROUTE {
    DOCUMENTATION = 'documentation',
    STUDIO = 'studio'
}

export const routesWithoutWorkspace: string[] = [ROUTE.DOCUMENTATION, ROUTE.STUDIO];

export const CLI_PROXY_URL_EXAMPLE = 'http://defra1c-proxy.emea.nsn-net.net:8080';
export const CLI_PASSWORD_EXAMPLE = 'w^EU36BRDfntCWdx';
export const CLI_USER_EXAMPLE = 'cli';
export const CLI_USER_HELM_EXAMPLE = 'eiap-helm-reader';
export const CLI_HELM_NAME_EXAMPLE = 'EIAP-helm';
export const CLI_HELM_URL_EXAMPLE = 'https://nexus.msv-project.com/repository/EIAP-helm/';
export const CLI_PASSWORD_HELM_EXAMPLE = 'LtEmFfSE787!wKqC';

export const LOCALSTORAGE_DISPLAY_MODE = 'eiap-display-mode';
export const LOCALSTORAGE_TOUR_GUIDE = 'eiap-tour-guide';
export const LOCALSTORAGE_PROVIDERS_CURRENT_GROUP = 'eiap-providers-current-group';

export const INSTANCE_STATUS_TIMEOUT = 5000;
