import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Dependency, InstanceStatus, Version } from 'shared/utils/interfaces/interfaces';
import { getCurrentUserJwtToken } from 'shared/utils/helpers/currentUser.helpers';
import {
    INSTANCE_STATUS_TIMEOUT,
    UPDATE_STATUS
} from 'customers/utils/constants/general.constants';

import _ from 'lodash';
import yaml from 'js-yaml';
import { INSTANCE_STATUS } from 'customers/utils/enums/enums';
import { Instance } from 'shared/utils/classes/Instance';

interface Endpoint {
    url: string;
    disable_eiap_bearer_token: boolean;
}

export const instancesStatusApi = createApi({
    reducerPath: 'instancesStatusApi',
    baseQuery: fetchBaseQuery({
        baseUrl: ``,
        prepareHeaders: async (headers) => {
            const accessToken = await getCurrentUserJwtToken();
            headers.set('Content-Type', 'application/json');
            headers.set('Authorization', `Bearer ${accessToken}`);
            return headers;
        }
    }),
    tagTypes: ['instancesStatus'],
    endpoints: (builder) => ({
        getInstanceStatus: builder.query<INSTANCE_STATUS, Instance>({
            async queryFn(arg: Instance, queryApi, extraOptions, fetchWithBQ) {
                const instance = arg;
                const keepaliveEndpoints: Endpoint[] = [];

                if (instance.isCreating()) {
                    return { data: INSTANCE_STATUS.PROCESSING };
                } else if (instance.isUpdating()) {
                    return { data: INSTANCE_STATUS.PROCESSING };
                } else if (instance.isDeleting()) {
                    return { data: INSTANCE_STATUS.PROCESSING };
                } else if (instance.isFailed()) {
                    return { data: INSTANCE_STATUS.UNAVAILABLE };
                } else if (instance.isRunning()) {
                    const version: Version = instance.version!;
                    if (version) {
                        Object.keys(version.microservices).map((microserviceName: string) => {
                            const hostPath = version.microservices[
                                microserviceName
                            ].dependencies?.find(
                                (dependency: Dependency) => dependency.used_by_the_platform_as_host
                            )?.parameter;
                            if (
                                hostPath &&
                                version.microservices[microserviceName].keepalive_endpoint
                            ) {
                                const yamlFile = yaml.load(
                                    version.microservices[microserviceName].values_yaml
                                );
                                const host = _.get(yamlFile, hostPath);
                                const endpoint = {
                                    url:
                                        'https://' +
                                        host +
                                        version.microservices[microserviceName].keepalive_endpoint[
                                            'value'
                                        ],
                                    disable_eiap_bearer_token:
                                        !!version.microservices[microserviceName]
                                            .keepalive_endpoint['disable_eiap_bearer_token']
                                };
                                keepaliveEndpoints.push(endpoint);
                            }
                        });
                    }
                    if (keepaliveEndpoints.length > 0) {
                        const accessToken = await getCurrentUserJwtToken();
                        try {
                            const controller = new AbortController();

                            const timerId = setTimeout(() => {
                                controller.abort();
                            }, INSTANCE_STATUS_TIMEOUT);
                            const responses = await Promise.all(
                                keepaliveEndpoints.map((endpoint: Endpoint) => {
                                    const header = endpoint.disable_eiap_bearer_token
                                        ? ({
                                              'Content-Type': 'application/json'
                                          } as HeadersInit)
                                        : ({
                                              'Content-Type': 'application/json',
                                              Authorization: `Bearer ${accessToken}`
                                          } as HeadersInit);

                                    return fetch(`${endpoint.url}`, {
                                        method: 'GET',
                                        headers: header,
                                        signal: controller.signal
                                    });
                                })
                            );

                            clearTimeout(timerId);
                            const isOk = (response: any) => response.ok;
                            if (responses.every(isOk)) {
                                return { data: INSTANCE_STATUS.RUNNING };
                            } else {
                                return { data: INSTANCE_STATUS.UNAVAILABLE };
                            }
                        } catch (error: any) {
                            if (error?.message?.match(/(aborted)/i)) {
                                return { data: INSTANCE_STATUS.TIMED_OUT };
                            } else {
                                return { data: INSTANCE_STATUS.NOT_HANDLED };
                            }
                        }
                    } else {
                        return { data: INSTANCE_STATUS.NOT_HANDLED };
                    }
                } else {
                    return { data: INSTANCE_STATUS.NOT_HANDLED };
                }
            }
        })
    })
});

export const { useGetInstanceStatusQuery } = instancesStatusApi;
