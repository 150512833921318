import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_BASE_URL } from 'shared/utils/appConfig';
import { Item } from 'shared/utils/interfaces/interfaces';
import {
    getCurrentUserEmail,
    getCurrentUserJwtToken
} from 'shared/utils/helpers/currentUser.helpers';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';

interface deleteItemVersionArgs {
    itemId: string;
    versionNames: string[];
}
const TAG_ITEM = 'item';
const TAG_ITEMS = 'items';
const TAG_MY_ITEMS = 'my-items';
export const itemsApi = createApi({
    reducerPath: 'itemsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${API_BASE_URL}/items/items`,
        prepareHeaders: async (headers) => {
            const accessToken = await getCurrentUserJwtToken();
            headers.set('Content-Type', 'application/json');
            headers.set('Authorization', `Bearer ${accessToken}`);
            return headers;
        }
    }),
    tagTypes: [TAG_ITEMS, TAG_ITEM, TAG_MY_ITEMS],
    endpoints: (builder) => ({
        getItem: builder.query<Item | null, string>({
            query: (id) => `/${id}`,
            transformResponse: (response: any) => {
                return response.item;
            },
            providesTags: [TAG_ITEM]
        }),
        getItems: builder.query<Item[], void>({
            query: () => ``,
            transformResponse: (response: any) => {
                return response.items;
            },
            providesTags: [TAG_ITEMS]
        }),
        getMyItems: builder.query<Item[], string>({
            query: () => ``,
            transformResponse: (response: any, meta: any, arg: any) => {
                return response.items?.filter((item: Item) => item.provider_group === arg);
            },
            providesTags: [TAG_MY_ITEMS]
        }),
        addItem: builder.mutation<any, Partial<Item>>({
            async queryFn(arg: Item, queryApi, extraOptions, fetchWithBQ) {
                const userEmail = await getCurrentUserEmail();
                const body: any = {};
                body.name = arg.name;
                body.description = arg.description;
                body.abstract = arg.abstract;
                body.owners = [userEmail];
                body.is_published = arg.is_published;
                body.type = arg.type;

                const result = await fetchWithBQ({
                    url: ``,
                    method: 'POST',
                    body: body
                });
                return { data: result };
            },
            invalidatesTags: [TAG_ITEMS, TAG_MY_ITEMS]
        }),
        editItem: builder.mutation<any, Partial<Item>>({
            async queryFn(arg: Item, queryApi, extraOptions, fetchWithBQ) {
                const userEmail = await getCurrentUserEmail();
                const body: any = {};
                body.name = arg.name;
                body.description = arg.description;
                body.abstract = arg.abstract;
                body.owner = userEmail;
                body.is_published = arg.is_published;
                body.type = arg.type;

                const result: any = await fetchWithBQ({
                    url: `${arg.id}`,
                    method: 'PUT',
                    body: body
                });

                if (result.error) {
                    const returnError =
                        result.error?.data?.detail && typeof result.error?.data?.detail === 'string'
                            ? result.error?.data?.detail
                            : null;
                    return {
                        error: (returnError as FetchBaseQueryError) || 'internal error'
                    };
                }
                return { data: result };
            },
            invalidatesTags: [TAG_ITEMS, TAG_ITEM, TAG_MY_ITEMS]
        }),
        deleteItemVersion: builder.mutation<any, deleteItemVersionArgs>({
            async queryFn(arg: deleteItemVersionArgs, queryApi, extraOptions, fetchWithBQ) {
                const responses = await Promise.all(
                    arg.versionNames.map((versionName: string) => {
                        return fetchWithBQ({
                            url: `${arg.itemId}/versions/${versionName}`,
                            method: 'DELETE'
                        });
                    })
                );
                const isError = (response: any) => response.error;
                if (!responses.some(isError)) {
                    return { data: responses };
                } else {
                    const resp = responses.find((resp: any) => !resp.error);
                    return { error: resp!.error as FetchBaseQueryError };
                }
            },
            invalidatesTags: [TAG_ITEMS, TAG_ITEM, TAG_MY_ITEMS]
        }),
        deleteItem: builder.mutation<string, string>({
            query: (id) => ({
                url: `${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: [TAG_ITEMS, TAG_ITEM, TAG_MY_ITEMS]
        })
    })
});

export const {
    useGetItemQuery,
    useGetItemsQuery,
    useGetMyItemsQuery,
    useAddItemMutation,
    useEditItemMutation,
    useDeleteItemMutation,
    useDeleteItemVersionMutation
} = itemsApi;
