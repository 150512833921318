import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_BASE_URL } from 'shared/utils/appConfig';
import { Device } from 'shared/utils/interfaces/interfaces';
import {
    getCurrentUserEmail,
    getCurrentUserJwtToken
} from 'shared/utils/helpers/currentUser.helpers';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';

export const devicesApi = createApi({
    reducerPath: 'devicesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${API_BASE_URL}/devices`,
        prepareHeaders: async (headers) => {
            const accessToken = await getCurrentUserJwtToken();
            headers.set('Content-Type', 'application/json');
            headers.set('Authorization', `Bearer ${accessToken}`);
            return headers;
        }
    }),
    tagTypes: ['devices'],
    endpoints: (builder) => ({
        getDevices: builder.query<Device[], string>({
            query: (workspaceId) => `/workspace/'${workspaceId}`,
            transformResponse: (response: any) => {
                return response.devices;
            },
            providesTags: ['devices']
        })
    })
});

export const { useGetDevicesQuery } = devicesApi;
