export enum INSTANCE_STATUS {
    LOADING = 'LOADING',
    PROCESSING = 'PROCESSING',
    RUNNING = 'RUNNING',
    UNAVAILABLE = 'UNAVAILABLE',
    NOT_HANDLED = 'NOT_HANDLED',
    TIMED_OUT = 'TIMED_OUT'
}

export enum INSTANTIABLE_STATUS {
    NO_VERSION = 'No version released',
    INCORRECT_DATA_MODEL = 'The software is outdated, please remove incompatible versions from the portal, or publish a new version using the latest CI pipeline version',
    INSTANTIABLE = 'INSTANTIABLE',
    NO_TENANT = 'You need a workspace to instantiate',
    NO_CLOUD = 'You need to register a cloud first',
    CLOUD_OFF = 'Your clouds are offline or not working properly',
    NO_PERMISSION = 'NO_PERMISSION',
    ALREADY_INSTANTIATED = 'This item has already been instantiated',
    NOT_INSTANTIABLE = 'NOT_INSTANTIABLE'
}
export enum UPDATABLE_STATUS {
    INSTALLING = 'Installing...',
    UP_TO_DATE = 'Already up to date',
    UPDATABLE = 'UPDATABLE',
    NO_CLOUD = 'You need to register a cloud',
    CLOUD_OFF = 'Your clouds are offline or not working properly'
}

export enum INSTANTIATE_ERROR {
    INTERNAL_ERROR = 'INTERNAL_ERROR',
    INPUT_ERROR = 'INPUT_ERROR',
    NO_CLOUD = 'NO_CLOUD'
}

export enum WORKSPACE_TYPE {
    CUSTOMER = 'CUSTOMER',
    DEVELOPER = 'DEVELOPER',
    DEVICE_VENDOR = 'DEVICE_VENDOR',
    DENIED = 'DENIED'
}
export enum WORKSPACE_ROLE {
    ADMIN = 'ADMIN',
    END_USER = 'END_USER',
    DENIED = 'DENIED'
}
export enum WORKSPACE_CODE {
    NOT_ALLOWED = 'NOT_ALLOWED',
    NOT_SELECTED = 'NOT_SELECTED',
    OK = 'OK',
    EMPTY = 'EMPTY'
}
