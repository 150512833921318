import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { InstanceLog } from 'shared/utils/interfaces/interfaces';
import { getCurrentUserJwtToken } from 'shared/utils/helpers/currentUser.helpers';
import { ENVIRONMENT } from 'shared/utils/appConfig';

interface getInstanceLogsArgs {
    workspaceId: string;
    instanceId: string;
}
export const instanceLogsApi = createApi({
    reducerPath: 'instanceLogsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: ``,
        prepareHeaders: async (headers) => {
            const accessToken = await getCurrentUserJwtToken();
            headers.set('Content-Type', 'text/plain');
            headers.set('kbn-version', '7.10.2');
            headers.set('Authorization', `Bearer ${accessToken}`);
            return headers;
        }
    }),
    tagTypes: ['instanceLogs'],
    endpoints: (builder) => ({
        getInstanceLogs: builder.query<InstanceLog[], getInstanceLogsArgs>({
            async queryFn(arg: getInstanceLogsArgs, queryApi, extraOptions, fetchWithBQ) {
                const kbnPostInfo = {
                    params: {
                        index: 'logstash*',
                        body: {
                            version: true,
                            size: 500,
                            sort: [
                                {
                                    '@timestamp': {
                                        order: 'asc',
                                        unmapped_type: 'boolean'
                                    }
                                }
                            ],
                            aggs: {
                                '2': {
                                    date_histogram: {
                                        field: '@timestamp',
                                        calendar_interval: '1w',
                                        time_zone: 'Europe/Paris',
                                        min_doc_count: 1
                                    }
                                }
                            },
                            stored_fields: ['*'],
                            script_fields: {},
                            docvalue_fields: [
                                {
                                    field: '@timestamp',
                                    format: 'date_time'
                                },
                                {
                                    field: 't.$date',
                                    format: 'date_time'
                                }
                            ],
                            _source: {
                                excludes: [],
                                includes: ['message', 'log_type', 'stream']
                            },
                            query: {
                                bool: {
                                    must: [],
                                    filter: [
                                        {
                                            bool: {
                                                filter: [
                                                    {
                                                        bool: {
                                                            should: [
                                                                {
                                                                    match: {
                                                                        'kubernetes.container_name':
                                                                            'eiap-daemon'
                                                                    }
                                                                }
                                                            ],
                                                            minimum_should_match: 1
                                                        }
                                                    },
                                                    {
                                                        bool: {
                                                            should: [
                                                                {
                                                                    match: {
                                                                        instance_id: arg.instanceId
                                                                    }
                                                                }
                                                            ],
                                                            minimum_should_match: 1
                                                        }
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            range: {
                                                '@timestamp': {
                                                    gte: '2021-01-20T13:39:35.558Z',
                                                    format: 'strict_date_optional_time'
                                                }
                                            }
                                        }
                                    ],
                                    should: [],
                                    must_not: []
                                }
                            },
                            highlight: {
                                pre_tags: ['@kibana-highlighted-field@'],
                                post_tags: ['@/kibana-highlighted-field@'],
                                fields: {
                                    '*': {}
                                },
                                fragment_size: 2147483647
                            }
                        },
                        preference: 1642766403963
                    }
                };

                const environment = ENVIRONMENT !== 'production' ? '.' + ENVIRONMENT : '';
                const logsResult = await fetchWithBQ({
                    url: `https://central-log.${arg.workspaceId}${environment}.msv-project.com/internal/search/es`,
                    method: 'POST',
                    body: JSON.stringify(kbnPostInfo)
                });

                if (logsResult.error) throw logsResult.error;
                const instanceLogs: any = logsResult.data;
                const response = instanceLogs['rawResponse']['hits']['hits'].map((hit: any) => {
                    return hit['_source'];
                });
                return { data: response as InstanceLog[] };
            },
            providesTags: ['instanceLogs']
        })
    })
});

export const { useGetInstanceLogsQuery } = instanceLogsApi;
