import { Auth } from 'aws-amplify';

export const getCurrentUserJwtToken = (): Promise<string> => {
    return Auth.currentSession()
        .then((currentUser) => currentUser.getAccessToken().getJwtToken())
        .catch(() => {
            return Promise.resolve('unavailable');
        });
};
export const getCurrentUsername = (): Promise<any> => {
    return Auth.currentSession()
        .then((currentUser) => currentUser.getAccessToken().decodePayload()['username'])
        .catch(() => {
            return Promise.resolve('unavailable');
        });
};
export const getCurrentUserEmail = (): Promise<any> => {
    return Auth.currentSession()
        .then((currentUser) => currentUser.getIdToken().decodePayload()['email'])
        .catch(() => {
            return Promise.resolve('unavailable');
        });
};
export const getCurrentName = (): Promise<any> => {
    return Auth.currentSession()
        .then((currentUser) => currentUser.getIdToken().decodePayload()['name'])
        .catch(() => {
            return Promise.resolve('unavailable');
        });
};
