import React, { useState } from 'react';
import { configureAmplify } from 'shared/utils/amplify';
import { Auth } from 'aws-amplify';
import { CssBaseline } from '@mui/material';
import CustomersRouter from 'customers/pages/CustomersRouter';
import { BrowserRouter } from 'react-router-dom';
import ProvidersRouter from 'providers/ProvidersRouter';
import LoadingBackdrop from 'shared/components/loading/LoadingBackdrop';

const App = () => {
    const [logged, setLogged] = useState<boolean>(false);

    React.useEffect(() => {
        configureAmplify();
        Auth.currentSession()
            .then((user) => {
                console.log('user', user);
                if (user && user.getAccessToken().getJwtToken()) {
                    setLogged(true);
                } else {
                    Auth.federatedSignIn();
                }
            })
            .catch((e) => {
                Auth.federatedSignIn();
            });
    }, []);

    // const providersHost = window.location.hostname.match(/localhost.*/);
    // todo: to uncomment
    const providersHost = window.location.hostname.match(/providers.*/);

    return (
        <React.Fragment>
            {logged ? (
                <>
                    <CssBaseline />
                    {providersHost ? <ProvidersRouter /> : <CustomersRouter />}
                </>
            ) : (
                <LoadingBackdrop />
            )}
        </React.Fragment>
    );
};

export default App;
