import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_BASE_URL } from 'shared/utils/appConfig';
import {
    Item,
    MicroService,
    MicroServices,
    Version,
    YamlFile
} from 'shared/utils/interfaces/interfaces';
import {
    getCurrentUserEmail,
    getCurrentUserJwtToken
} from 'shared/utils/helpers/currentUser.helpers';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { IInstance, Instance } from 'shared/utils/classes/Instance';

interface addInstanceArgs {
    name: string;
    description: string;
    item: Item;
    selectedVersion: Version;
    yamlFiles: YamlFile[];
    cloudId: string;
    currentWorkspaceId: string;
}
interface editInstanceArgs {
    name: string;
    description: string;
    instance: Instance;
    selectedVersion: Version;
    yamlFiles: YamlFile[];
}
export const instancesApi = createApi({
    reducerPath: 'instancesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${API_BASE_URL}/instances`,
        prepareHeaders: async (headers) => {
            const accessToken = await getCurrentUserJwtToken();
            headers.set('Content-Type', 'application/json');
            headers.set('Authorization', `Bearer ${accessToken}`);
            return headers;
        }
    }),
    tagTypes: ['instances', 'instance'],
    endpoints: (builder) => ({
        getInstances: builder.query<Instance[], string>({
            query: (workspaceId) => `?workspace_id=${workspaceId}`,
            transformResponse: (response: IInstance[]) => {
                return response?.map((instanceData: IInstance) => new Instance(instanceData));
            },
            providesTags: ['instances']
        }),
        getInstance: builder.query<any, string>({
            async queryFn(arg: string, queryApi, extraOptions, fetchWithBQ) {
                const accessToken = await getCurrentUserJwtToken();
                const instanceId = arg;
                const instanceResult = await fetchWithBQ({
                    url: `/${instanceId}`,
                    method: 'GET'
                });

                if (instanceResult.error) throw instanceResult.error;
                const instance = new Instance(instanceResult.data as IInstance);

                const itemResult = await fetch(`${API_BASE_URL}/items/items/${instance.item_id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`
                    }
                }).then((response) => response.json());
                instance.item = itemResult.item;
                return { data: instance };
            },
            providesTags: ['instance']
        }),
        deleteInstance: builder.mutation<string, string>({
            query: (id) => ({
                url: `/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['instances', 'instance']
        }),
        addInstance: builder.mutation<any, addInstanceArgs>({
            async queryFn(arg: addInstanceArgs, queryApi, extraOptions, fetchWithBQ) {
                const userEmail = await getCurrentUserEmail();
                const body: any = {};
                body.name = arg.name;
                body.item_id = arg.item.id;
                body.item_version_name = arg.selectedVersion.name;
                body.cloud_id = arg.cloudId;
                body.description = arg.description;
                const microServices: MicroServices = {};
                arg.yamlFiles.map((yamlFile: YamlFile) => {
                    microServices[yamlFile.microservice_name] = {
                        values_yaml: yamlFile.value
                    } as MicroService;
                });
                body.microservices = microServices;
                body.owner = userEmail;
                console.log('POST', body);
                const result: any = await fetchWithBQ({
                    url: `?workspace_id=${arg.currentWorkspaceId}`,
                    method: 'POST',
                    body: body
                });

                if (result.error) {
                    const returnError =
                        result.error?.data?.detail && typeof result.error?.data?.detail === 'string'
                            ? result.error?.data?.detail
                            : null;
                    return {
                        error: (returnError as FetchBaseQueryError) || 'internal error'
                    };
                }
                return { data: result };
            },
            invalidatesTags: ['instances', 'instance']
        }),
        editInstance: builder.mutation<any, editInstanceArgs>({
            async queryFn(arg: editInstanceArgs, queryApi, extraOptions, fetchWithBQ) {
                const body: any = {};
                body.item_version_name = arg.selectedVersion.name;
                const microServices: MicroServices = {};
                arg.yamlFiles.map((yamlFile: YamlFile) => {
                    microServices[yamlFile.microservice_name] = {
                        values_yaml: yamlFile.value
                    } as MicroService;
                });
                body.microservices = microServices;
                console.log('PATCH', body);
                const result: any = await fetchWithBQ({
                    url: `${arg.instance.id}`,
                    method: 'PATCH',
                    body: body
                });

                if (result.error) {
                    const returnError =
                        result.error?.data?.detail && typeof result.error?.data?.detail === 'string'
                            ? result.error?.data?.detail
                            : null;
                    return {
                        error: (returnError as FetchBaseQueryError) || 'internal error'
                    };
                }
                return { data: result };
            },
            invalidatesTags: ['instances', 'instance']
        })
    })
});

export const {
    useDeleteInstanceMutation,
    useAddInstanceMutation,
    useEditInstanceMutation,
    useGetInstancesQuery,
    useGetInstanceQuery
} = instancesApi;
