import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_BASE_URL } from 'shared/utils/appConfig';
import { Cloud } from 'shared/utils/interfaces/interfaces';
import { getCurrentUserJwtToken } from 'shared/utils/helpers/currentUser.helpers';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { CLOUDS_SSE_EVENT } from 'customers/utils/constants/general.constants';

export const cloudsApi = createApi({
    reducerPath: 'cloudsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${API_BASE_URL}/clouds`,
        prepareHeaders: async (headers) => {
            const accessToken = await getCurrentUserJwtToken();
            headers.set('Content-Type', 'application/json');
            headers.set('Authorization', `Bearer ${accessToken}`);
            return headers;
        }
    }),
    tagTypes: ['clouds', 'cloud'],
    endpoints: (builder) => ({
        getClouds: builder.query<Cloud[], string>({
            query: (workspaceId) => `?workspace_id=${workspaceId}`,
            transformResponse: (response: Cloud[]) => {
                return response;
            },
            async onCacheEntryAdded(arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
                const accessToken = await getCurrentUserJwtToken();
                const url = API_BASE_URL + '/clouds/stream?workspace_id=' + arg;
                const eventSource = new EventSourcePolyfill(url, {
                    headers: { Authorization: 'Bearer ' + accessToken }
                });
                try {
                    await cacheDataLoaded;

                    eventSource.addEventListener(CLOUDS_SSE_EVENT.CREATE, (event) => {
                        const data = JSON.parse(event.data);
                        updateCachedData((draft) => {
                            draft.push(data);
                        });
                    });
                    eventSource.addEventListener(CLOUDS_SSE_EVENT.UPDATE, (event) => {
                        const data = JSON.parse(event.data);
                        updateCachedData((draft) => {
                            const index = draft.findIndex(
                                (cloud: Cloud) => cloud.cloud_id === data.cloud_id
                            );
                            if (index !== -1) draft.splice(index, 1);
                        });
                    });
                    eventSource.addEventListener(CLOUDS_SSE_EVENT.DELETE, (event) => {
                        const data = JSON.parse(event.data);
                        updateCachedData((draft) => {
                            const index = draft.findIndex(
                                (cloud: Cloud) => cloud.cloud_id === data.cloud_id
                            );
                            if (index !== -1) draft[index] = data;
                        });
                    });
                } catch {}
                await cacheEntryRemoved;
                eventSource.close();
            }
        })
    })
});

export const { useGetCloudsQuery } = cloudsApi;
