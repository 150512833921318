import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import LoadingBackdrop from 'shared/components/loading/LoadingBackdrop';
import './Providers.scss';
import { useGetGroupsQuery } from 'store/groups/groupsApi';
import { Group } from 'shared/utils/interfaces/interfaces';
import {
    loadFromLocalStorage,
    saveToLocalStorage
} from 'shared/utils/helpers/localStorage.helpers';
import { LOCALSTORAGE_PROVIDERS_CURRENT_GROUP } from 'customers/utils/constants/general.constants';

const Landing = lazy(() => import('providers/pages/landing/Landing'));
const Portfolio = lazy(() => import('providers/pages/portfolio/Portfolio'));
const PortfolioAdd = lazy(() => import('providers/pages/portfolio-add/PortfolioAdd'));
const UnavailableGroup = lazy(() => import('providers/pages/unavailable-group/UnavailableGroup'));

const ProvidersRouter = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { data: groups, isLoading: isLoadingGroups } = useGetGroupsQuery();
    const [validatedGroupNameInPath, setValidatedGroupNameInPath] = useState<string | null>(null);
    const [unavailableGroupNameInPath, setUnavailableGroupNameInPath] = useState<string | null>(
        null
    );
    const [loaded, setLoaded] = useState<boolean>(false);
    useEffect(() => {
        document.title = 'EIAP for providers';
    }, []);

    const isGroupNew = (groupName: string): boolean => {
        if (!groupName) {
            return false;
        } else return !validatedGroupNameInPath || validatedGroupNameInPath !== groupName;
    };
    useEffect(() => {
        const newGroupNameInPath = location.pathname.split('/')[1];

        if (isGroupNew(newGroupNameInPath) && groups) {
            setValidatedGroupNameInPath(null);
            setUnavailableGroupNameInPath(null);
            if (groups?.find((group: Group) => group.group_id === newGroupNameInPath)) {
                setValidatedGroupNameInPath(newGroupNameInPath);
            } else {
                setUnavailableGroupNameInPath(newGroupNameInPath);
            }
        }
    }, [location, groups]);

    useEffect(() => {
        if (!loaded) {
            if (location.pathname === '/') {
                const validatedGroupInLocalStorage = groups?.find(
                    (group: Group) =>
                        group.group_id ===
                        loadFromLocalStorage(LOCALSTORAGE_PROVIDERS_CURRENT_GROUP)
                );
                if (groups?.length === 1) {
                    navigate(`/${groups[0].group_id}`);
                    setLoaded(true);
                } else if (validatedGroupInLocalStorage) {
                    navigate(`/${validatedGroupInLocalStorage.group_id}`);
                    setLoaded(true);
                }
            }
        }
    }, [groups]);

    useEffect(() => {
        if (validatedGroupNameInPath) {
            saveToLocalStorage(LOCALSTORAGE_PROVIDERS_CURRENT_GROUP, validatedGroupNameInPath);
        }
    }, [validatedGroupNameInPath]);

    return (
        <Suspense fallback={<LoadingBackdrop />}>
            <Routes>
                <Route path={`/`} element={<Landing />} />
                <Route
                    path={`/registration/:groupId/:registrationToken`}
                    key={`/registration/:groupId/:registrationToken`}
                    element={<Landing />}
                />

                {validatedGroupNameInPath && (
                    <>
                        <Route
                            path={`/:groupId`}
                            key={`/:groupId`}
                            element={<Navigate to={`portfolio`} replace />}
                        />
                        <Route path={`/:groupId/portfolio`} element={<Portfolio />} />
                        <Route path={`/:groupId/portfolio/add`} element={<PortfolioAdd />} />
                        <Route path={`*`} element={<Navigate to={`/`} replace />} />
                    </>
                )}
                {isLoadingGroups && <Route path={`*`} element={<LoadingBackdrop />} />}
                {!isLoadingGroups && unavailableGroupNameInPath !== null && (
                    <Route path={`*`} element={<UnavailableGroup />} />
                )}
                {!isLoadingGroups && validatedGroupNameInPath !== null && (
                    <Route path={`*`} element={<Navigate replace to={`/`} />} />
                )}
            </Routes>
        </Suspense>
    );
};

export default ProvidersRouter;
