import { Cloud, Item, Version, Workspace } from 'shared/utils/interfaces/interfaces';
import { INSTANTIABLE_STATUS } from 'customers/utils/enums/enums';
import { CLOUD_STATUS } from 'customers/utils/constants/general.constants';
import semver from 'semver/preload';
import { Instance } from 'shared/utils/classes/Instance';

const itemHasInstances = (item: Item, instances: Instance[]): boolean => {
    return instances.some((instance: Instance) => {
        return instance.item_id === item.id;
    });
};

export const removeSchemeFromUrl = (url: string): string => {
    return url?.replace(/(^\w+:|^)\/\//, '');
};

export const searchItemInItems = (id: string, items: Item[]): Item => {
    const item = items.find((i) => i.id === id);
    return item as Item;
};

export const secureString = (str: string): string => {
    const newString = str.replace(/[^\w]/g, '-');
    return newString.toLowerCase();
};

export const getHighestVersions = (targetVersion: Version, versions?: Version[]): Version[] => {
    if (!versions || versions.length === 0) {
        return [targetVersion];
    }
    const versionNamesToSort = versions.map((version: Version) => version.name);
    const sortedVersionNames = versionNamesToSort.sort(semver.compare);
    const sortedAndHigherVersions: Version[] = [];
    sortedVersionNames.forEach((sortedVersionName: string) => {
        versions.forEach((version: Version) => {
            if (
                version.name === sortedVersionName &&
                semver.gte(sortedVersionName, targetVersion.name)
            ) {
                sortedAndHigherVersions.push(version);
            }
        });
    });
    return sortedAndHigherVersions;
};

export const orderVersions = (versions: Version[]): Version[] => {
    if (versions.length === 0) return [];
    const versionNamesToSort = versions.map((version: Version) => version.name);
    const sortedVersionNames = versionNamesToSort.sort(semver.compare);
    const sortedVersions: Version[] = [];
    sortedVersionNames.forEach((sortedVersionName: string) => {
        versions.forEach((version: Version) => {
            if (version.name === sortedVersionName) {
                sortedVersions.push(version);
            }
        });
    });
    return sortedVersions;
};

export const getErrorMessage = (error: unknown) => {
    if (error instanceof Error) return error.message;
    return String(error);
};

export const versionHasNoValuesYaml = (version: Version): boolean => {
    return Object.keys(version.microservices).every((key: any) => {
        return !version.microservices[key]?.values_yaml;
    });
};

export const areAllCloudsOffline = (clouds: Cloud[]): boolean => {
    return clouds.every((cloud: Cloud) => cloud.status !== CLOUD_STATUS.ONLINE);
};

export const existsHigherVersionsForItem = (
    instanceVersion?: Version,
    instanceItem?: Item
): boolean => {
    return (
        Boolean(instanceVersion) &&
        Boolean(instanceItem?.versions) &&
        getHighestVersions(instanceVersion!, instanceItem?.versions).length > 1
    );
};

export const isItemInstantiable = (
    item: Item,
    currentWorkspace: Workspace | null,
    instances: Instance[],
    clouds: Cloud[] | null
): INSTANTIABLE_STATUS => {
    if (!item) {
        return INSTANTIABLE_STATUS.NOT_INSTANTIABLE;
    }
    if (
        !item.versions ||
        item.versions.length === 0 ||
        !item.versions.every((version: Version) => version.microservices)
    ) {
        return INSTANTIABLE_STATUS.NO_VERSION;
    } else if (item.versions.every(versionHasNoValuesYaml)) {
        return INSTANTIABLE_STATUS.INCORRECT_DATA_MODEL;
    } else if (!currentWorkspace) {
        return INSTANTIABLE_STATUS.NO_TENANT;
    } else if (item.singleton && itemHasInstances(item, instances)) {
        return INSTANTIABLE_STATUS.ALREADY_INSTANTIATED;
    } else if (!clouds || clouds.length === 0) {
        return INSTANTIABLE_STATUS.NO_CLOUD;
    } else if (areAllCloudsOffline(clouds)) {
        return INSTANTIABLE_STATUS.CLOUD_OFF;
    } else {
        return INSTANTIABLE_STATUS.INSTANTIABLE;
    }
};
