import { CurrentWorkspaceResult, Workspace } from 'shared/utils/interfaces/interfaces';
import { WORKSPACE_CODE } from 'customers/utils/enums/enums';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    getCurrentUserJwtToken,
    getCurrentUsername
} from 'shared/utils/helpers/currentUser.helpers';
import { routesWithoutWorkspace } from 'customers/utils/constants/general.constants';
import { API_BASE_URL } from 'shared/utils/appConfig';

interface CurrentWorkspaceState {
    workspace: Workspace | null;
    code: WORKSPACE_CODE | null;
    loading: boolean;
    error: boolean;
}

const initialState: CurrentWorkspaceState = {
    workspace: null,
    code: null,
    loading: false,
    error: false
};

export const getCurrentWorkspace = createAsyncThunk(
    'currentWorkspaceSlice/getCurrentWorkspace',
    async () => {
        const username = await getCurrentUsername();
        const accessToken = await getCurrentUserJwtToken();

        const workspacesResult = await fetch(`${API_BASE_URL}/workspaces?username=${username}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const workspaces = await workspacesResult.json();

        const workspaceInPathname = window.location.pathname.split('/')[1];
        if (workspaces.length === 0) {
            if (workspaceInPathname) {
                return {
                    workspace: { workspace_id: workspaceInPathname } as Workspace,
                    code: WORKSPACE_CODE.NOT_ALLOWED
                } as CurrentWorkspaceState;
            } else {
                return {
                    workspace: null,
                    code: WORKSPACE_CODE.EMPTY
                } as CurrentWorkspaceState;
            }
        }
        if (workspaceInPathname && !routesWithoutWorkspace.includes(workspaceInPathname)) {
            const validWorkspaceInPathname: Workspace | undefined = workspaces.find(
                (workspace: Workspace) => workspace.workspace_id === workspaceInPathname
            );
            if (validWorkspaceInPathname) {
                return {
                    workspace: validWorkspaceInPathname as Workspace,
                    code: WORKSPACE_CODE.OK
                } as CurrentWorkspaceState;
            } else {
                return {
                    workspace: { workspace_id: workspaceInPathname } as Workspace,
                    code: WORKSPACE_CODE.NOT_ALLOWED
                } as CurrentWorkspaceState;
            }
        }
        return {
            workspace: null,
            code: WORKSPACE_CODE.NOT_SELECTED
        } as CurrentWorkspaceState;
    }
);

export const currentWorkspaceSlice = createSlice({
    name: 'currentWorkspaceSlice',
    initialState,
    reducers: {
        setCurrentWorkspace: (state: CurrentWorkspaceState, action: PayloadAction<Workspace>) => {
            state.workspace = action.payload;
            state.code = WORKSPACE_CODE.OK;
            state.error = false;
            state.loading = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getCurrentWorkspace.pending, (state, action) => {
            state.loading = true;
            state.error = false;
            state.workspace = null;
        });
        builder.addCase(getCurrentWorkspace.rejected, (state, action) => {
            state.error = true;
            state.loading = false;
        });
        builder.addCase(getCurrentWorkspace.fulfilled, (state, action) => {
            state.error = false;
            state.loading = false;
            state.workspace = action.payload.workspace;
            state.code = action.payload.code;
        });
    }
});

export const { setCurrentWorkspace } = currentWorkspaceSlice.actions;
export default currentWorkspaceSlice.reducer;
