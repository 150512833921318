import { UPDATE_STATUS } from 'customers/utils/constants/general.constants';
import { Cloud, Item, Version } from 'shared/utils/interfaces/interfaces';
import { UPDATABLE_STATUS } from 'customers/utils/enums/enums';
import {
    areAllCloudsOffline,
    existsHigherVersionsForItem
} from 'customers/utils/helpers/customers.helpers';

export interface IInstance {
    id: string;
    owner: string;
    item_id: string;
    description: string;
    tenant_name: string;
    name: string;
    item_name: string;
    new_targeted_version?: Version;
    version?: Version;
    complex_parameters?: any;
    item: Item;
    status?: UPDATE_STATUS;
}
export class Instance {
    private _id: string = '';
    private _owner: string = '';
    private _item_id: string = '';
    private _description: string = '';
    private _tenant_name: string = '';
    private _name: string = '';
    private _item_name: string = '';
    private _new_targeted_version?: Version;
    private _version?: Version;
    private _complex_parameters?: any;
    private _item: Item = {} as Item;
    private _status?: UPDATE_STATUS;

    constructor(data: IInstance) {
        Object.assign(this, data);
    }

    getUpdatableStatus(clouds: Cloud[], item: Item): UPDATABLE_STATUS | string {
        const instanceVersion = this.version ? this.version : this.new_targeted_version;
        if (this.status === UPDATE_STATUS.CREATING) {
            return UPDATABLE_STATUS.INSTALLING;
        } else if (!item) {
            return `${this.item_name} was removed from the catalog by its owner`;
        } else if (!clouds || clouds.length === 0) {
            return UPDATABLE_STATUS.NO_CLOUD;
        } else if (areAllCloudsOffline(clouds)) {
            return UPDATABLE_STATUS.CLOUD_OFF;
        } else if (existsHigherVersionsForItem(instanceVersion, this.item)) {
            return UPDATABLE_STATUS.UPDATABLE;
        } else {
            return UPDATABLE_STATUS.UP_TO_DATE;
        }
    }

    getTooltipTitle(): string {
        if (this.isDeleting()) {
            return 'Deleting...';
        } else if (this.isCreating()) {
            return 'Installing...';
        } else if (this.isUpdating()) {
            return 'Updating...';
        } else {
            return '';
        }
    }

    isUpdating(): boolean {
        return this._status === UPDATE_STATUS.UPDATING;
    }
    isCreating(): boolean {
        return this._status === UPDATE_STATUS.CREATING;
    }
    isDeleting(): boolean {
        return this._status === UPDATE_STATUS.DELETING;
    }
    isRunning(): boolean {
        return this._status === UPDATE_STATUS.RUNNING;
    }
    isFailed(): boolean {
        return this._status === UPDATE_STATUS.FAILED;
    }

    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }

    get owner(): string {
        return this._owner;
    }

    set owner(value: string) {
        this._owner = value;
    }

    get item_id(): string {
        return this._item_id;
    }

    set item_id(value: string) {
        this._item_id = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get tenant_name(): string {
        return this._tenant_name;
    }

    set tenant_name(value: string) {
        this._tenant_name = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get item_name(): string {
        return this._item_name;
    }

    set item_name(value: string) {
        this._item_name = value;
    }

    get new_targeted_version(): Version | undefined {
        return this._new_targeted_version;
    }

    set new_targeted_version(value: Version | undefined) {
        this._new_targeted_version = value;
    }

    get version(): Version | undefined {
        return this._version;
    }

    set version(value: Version | undefined) {
        this._version = value;
    }

    get complex_parameters(): any {
        return this._complex_parameters;
    }

    set complex_parameters(value: any) {
        this._complex_parameters = value;
    }

    get item(): Item {
        return this._item;
    }

    set item(value: Item) {
        this._item = value;
    }

    get status(): UPDATE_STATUS | undefined {
        return this._status;
    }

    set status(value: UPDATE_STATUS | undefined) {
        this._status = value;
    }
}
