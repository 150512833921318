import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import 'index.css';
import * as serviceWorker from 'serviceWorker';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import App from 'App';
import theme from 'theme';
import 'source-code-pro/source-code-pro.css';
import { ThemeProvider } from '@mui/material/styles';
import { setupStore } from 'store/setupStore';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

root.render(
    <Provider store={setupStore}>
        <ThemeProvider theme={theme}>
            <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </SnackbarProvider>
        </ThemeProvider>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
