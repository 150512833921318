import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { API_BASE_URL } from 'shared/utils/appConfig';
import { Group, User } from 'shared/utils/interfaces/interfaces';
import {
    getCurrentUserJwtToken,
    getCurrentUsername
} from 'shared/utils/helpers/currentUser.helpers';

interface AddUsersArgs {
    groupId: string;
    registrationToken: string;
}

export const groupsApi = createApi({
    reducerPath: 'groupsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${API_BASE_URL}/groups`,
        prepareHeaders: async (headers) => {
            const accessToken = await getCurrentUserJwtToken();
            headers.set('Content-Type', 'application/json');
            headers.set('Authorization', `Bearer ${accessToken}`);
            return headers;
        }
    }),
    tagTypes: ['groups', 'members'],
    endpoints: (builder) => ({
        addMembers: builder.mutation<any, any>({
            async queryFn(arg: AddUsersArgs, queryApi, extraOptions, fetchWithBQ) {
                const username = await getCurrentUsername();
                const body: any = {
                    members: [username]
                };

                const result: any = await fetchWithBQ({
                    url: `${arg.groupId}?registration_token=${arg.registrationToken}`,
                    method: 'PATCH',
                    body: body
                });

                if (result.error) {
                    return {
                        error: (result.error?.data?.detail as FetchBaseQueryError) || 'error'
                    };
                }
                return { data: result };
            },
            invalidatesTags: ['groups', 'members']
        }),

        getGroups: builder.query<Group[], void>({
            async queryFn(arg, queryApi, extraOptions, fetchWithBQ) {
                const username = await getCurrentUsername();
                const groupsResult = await fetchWithBQ(`?username=${username}`);
                return groupsResult.data
                    ? { data: groupsResult.data as Group[] }
                    : { error: groupsResult.error as FetchBaseQueryError };
            },
            providesTags: ['groups']
        }),
        getGroupRegistrationToken: builder.query<string, string>({
            query: (groupId) => `/${groupId}/registration_token`,
            transformResponse: (response: any) => response['registration_token']
        }),
        getGroupMembers: builder.query<User[], string>({
            query: (groupId) => `/${groupId}/members`,
            providesTags: ['members']
        }),
        addGroup: builder.mutation<any, string>({
            async queryFn(arg, queryApi, extraOptions, fetchWithBQ) {
                const username = await getCurrentUsername();
                const groupName = arg;
                const addGroupResult: any = await fetchWithBQ({
                    url: `/${groupName}?username=${username}`,
                    method: 'post',
                    body: {}
                });

                if (addGroupResult.error) {
                    return {
                        error:
                            (addGroupResult.error?.data?.detail as FetchBaseQueryError) || 'error'
                    };
                }
                return { data: addGroupResult };
            },
            invalidatesTags: ['groups']
        })
    })
});

export const {
    useGetGroupsQuery,
    useGetGroupRegistrationTokenQuery,
    useAddGroupMutation,
    useAddMembersMutation,
    useGetGroupMembersQuery
} = groupsApi;
