import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { API_BASE_URL } from 'shared/utils/appConfig';
import { User, Workspace } from 'shared/utils/interfaces/interfaces';
import {
    getCurrentUserJwtToken,
    getCurrentUsername
} from 'shared/utils/helpers/currentUser.helpers';

interface AddUsersArgs {
    workspaceId: string;
    registrationToken: string;
}

export const workspacesApi = createApi({
    reducerPath: 'workspacesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${API_BASE_URL}/workspaces`,
        prepareHeaders: async (headers) => {
            const accessToken = await getCurrentUserJwtToken();
            headers.set('Content-Type', 'application/json');
            headers.set('Authorization', `Bearer ${accessToken}`);
            return headers;
        }
    }),
    tagTypes: ['workspaces', 'users'],
    endpoints: (builder) => ({
        addUsers: builder.mutation<any, any>({
            async queryFn(arg: AddUsersArgs, queryApi, extraOptions, fetchWithBQ) {
                const username = await getCurrentUsername();
                const body: any = {
                    members: [username]
                };

                const result: any = await fetchWithBQ({
                    url: `${arg.workspaceId}?registration_token=${arg.registrationToken}`,
                    method: 'PATCH',
                    body: body
                });

                if (result.error) {
                    return {
                        error: (result.error?.data?.detail as FetchBaseQueryError) || 'error'
                    };
                }
                return { data: result };
            },
            invalidatesTags: ['workspaces', 'users']
        }),
        getWorkspaces: builder.query<Workspace[], void>({
            async queryFn(arg, queryApi, extraOptions, fetchWithBQ) {
                const username = await getCurrentUsername();
                const workspacesResult = await fetchWithBQ(`?username=${username}`);
                return workspacesResult.data
                    ? { data: workspacesResult.data as Workspace[] }
                    : { error: workspacesResult.error as FetchBaseQueryError };
            },
            providesTags: ['workspaces']
        }),
        getRegistrationToken: builder.query<string, string>({
            query: (workspaceId) => `/${workspaceId}/registration_token`,
            transformResponse: (response: any) => response['registration_token']
        }),
        getWorkspaceUsers: builder.query<User[], string>({
            query: (workspaceId) => `/${workspaceId}/members`,
            providesTags: ['users']
        }),
        addWorkspace: builder.mutation<any, string>({
            async queryFn(arg, queryApi, extraOptions, fetchWithBQ) {
                const username = await getCurrentUsername();
                const workspaceName = arg;

                const addWorkspaceResult: any = await fetchWithBQ({
                    url: `/${workspaceName}?username=${username}`,
                    method: 'post',
                    body: {}
                });

                if (addWorkspaceResult.error) {
                    return {
                        error:
                            (addWorkspaceResult.error?.data?.detail as FetchBaseQueryError) ||
                            'error'
                    };
                }
                await new Promise((resolve) => setTimeout(resolve, 8000));
                return { data: addWorkspaceResult };
            },
            invalidatesTags: ['workspaces']
        })
    })
});

export const {
    useGetWorkspacesQuery,
    useGetRegistrationTokenQuery,
    useAddWorkspaceMutation,
    useAddUsersMutation,
    useGetWorkspaceUsersQuery
} = workspacesApi;
